import React, { useState } from "react";
import Header from "../Pages/Header";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/Promotion.css";

export default function Promotion() {
  const BgTabText =
    "https://fs.cdnxn.com/landingKing/Images/ImgPro/ImgPro1.png";
  const GamesAll = "https://fs.cdnxn.com/landingKing/Images/ImgPro/ImgPro2.png";
  const ProGames = "https://fs.cdnxn.com/landingKing/Images/ImgPro/ImgPro3.png";

  const BannerPro01 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/sport/sport1.jpg";
  const BannerPro02 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/sport/sport2.png";
  const BannerPro03 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/sport/sport3.jpg";
  const BannerPro04 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/sport/sport4.png";
  const BannerPro05 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/sport/sport5.png";
  const BannerPro06 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/sport/sport6.png";
  const BannerPro07 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/sport/sport7.png";
  const BannerPro08 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/sport/sport8.png";
  const BannerPro09 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/sport/sport9.png";
  const BannerPro010 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/sport/sport10.png";

  const BannerPro011 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/casino/casino1.jpg";
  const BannerPro012 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/casino/casino2.png";
  const BannerPro013 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/casino/casino3.png";
  const BannerPro014 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/casino/casino4.png";
  const BannerPro015 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/casino/casino5.png";
  const BannerPro016 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/casino/casino6.png";

  const BannerPro020 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/slot/slot1.png";
  const BannerPro021 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/slot/slot2.png";
  const BannerPro022 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/slot/slot3.png";
  const BannerPro023 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/slot/slot4.png";
  const BannerPro024 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/slot/slot5.png";
  const BannerPro025 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/slot/slot6.jpg";
  const BannerPro026 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/slot/slot7.jpg";
  const BannerPro027 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/slot/slot8.png";
  const BannerPro028 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/slot/slot9.jpg";

  const BannerPro030 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/lotto/lotto1.png";

  const [linkLogin, setLinkLogin] = useState("https://game.lsm99king.bet");

  return (
    <>
      <div>
        <Header />
        <div className="promotion">
          <div className="promotion-section">
            <div className="promotion--PC">
              <img src={ProGames} className="Pro--Games" />
              <div className="table--games--PC">
                <Row>
                  <img src={BgTabText} className="Bg--Tab--Text" />

                  <Tabs defaultActiveKey="tab-1">
                    <Tab eventKey="tab-1" title="ทั้งหมด">
                      <Row>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro01} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro02} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro03} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro04} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro05} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro06} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro07} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro08} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro09} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro010} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro011} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro012} className="w-100" />
                          </a>
                        </Col>
                        {/* <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro013} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro014} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro015} className="w-100" />
                          </a>
                        </Col> */}
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro016} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro020} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro021} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro022} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro023} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro024} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro025} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro026} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro027} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro028} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro030} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>

                    <Tab eventKey="tab-2" title="กีฬา">
                      <Row>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro01} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro02} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro03} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro04} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro05} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro06} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro07} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro08} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro09} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro010} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>

                    <Tab eventKey="tab-3" title="คาสิโน">
                      <Row>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro011} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro012} className="w-100" />
                          </a>
                        </Col>
                        {/* <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro013} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro014} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro015} className="w-100" />
                          </a>
                        </Col> */}
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro016} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>

                    <Tab eventKey="tab-4" title="สล๊อต">
                      <Row>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro020} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro021} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro022} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro023} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro024} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro025} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro026} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro027} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro028} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>

                    <Tab eventKey="tab-5" title="หวย">
                      <Row>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro030} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </Row>
              </div>
              <div className="All--Games">
                <img src={GamesAll} className="icon--Games--All" />
              </div>
            </div>

            <div className="Promotion--Mb">
              <img src={ProGames} className="Pro--Games" />
              <div className="table--games--PC">
                <Row>
                  <img src={BgTabText} className="Bg--Tab--Text" />

                  <Tabs defaultActiveKey="tab-1">
                    <Tab eventKey="tab-1" title="ทั้งหมด">
                      <Row>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro01} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro02} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro03} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro04} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro05} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro06} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro07} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro08} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro09} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro010} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro011} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro012} className="w-100" />
                          </a>
                        </Col>
                        {/* <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro013} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro014} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro015} className="w-100" />
                          </a>
                        </Col> */}
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro016} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro020} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro021} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro022} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro023} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro024} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro025} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro026} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro027} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro028} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro030} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>

                    <Tab eventKey="tab-2" title="กีฬา">
                      <Row>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro01} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro02} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro03} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro04} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro05} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro06} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro07} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro08} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro09} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro010} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>

                    <Tab eventKey="tab-3" title="คาสิโน">
                      <Row>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro011} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro012} className="w-100" />
                          </a>
                        </Col>
                        {/* <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro013} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro014} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro015} className="w-100" />
                          </a>
                        </Col> */}
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro016} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>

                    <Tab eventKey="tab-4" title="สล๊อต">
                      <Row>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro020} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro021} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro022} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro023} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro024} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro025} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro026} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro027} className="w-100" />
                          </a>
                        </Col>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro028} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>

                    <Tab eventKey="tab-5" title="หวย">
                      <Row>
                        <Col md={12} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={BannerPro030} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
