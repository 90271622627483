import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/Header.css";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Header() {
  const HeadPc = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg28.png";
  const IconHeadPc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg29.png";
  const RegisterPc = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg3.png";
  const LoginPc = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg4.png";
  const IconNotifyPc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg18.png";
  const IconMenuPc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg15.png";

  const TabMenuPc = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg10.png";
  const TabPlayIconPc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg27.png";

  const IconWalletPc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg23.png";
  const IconDepositPc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg24.png";
  const IconWithdrawPc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg25.png";
  const IconPromotionPc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg26.png";

  const TabBarTextPc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg30.png";
  const CloseTabTextPc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg14.png";

  // const IconHeadMb = "https://fs.cdnxn.com/landingKing/Images/ImgMb/king.gif";
  const HeadMb = "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb1.png";
  const IconHeadMb =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg29.png";
  const IconNotifyMb =
    "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb3.png";
  const IconMenuMb = "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb4.png";

  const FooterMb = "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb15.png";
  const TabPlayIconMb =
    "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb16.png";

  const TabBarTextMb =
    "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb5.png";
  const CloseTabTextMb =
    "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb6.png";

  const IconWalletMb =
    "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb17.png";
  const IconDepositMb =
    "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb18.png";
  const IconWithdrawMb =
    "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb19.png";
  const IconPromotionMb =
    "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb20.png";

  const Iconclose = "https://fs.cdnxn.com/landingKing/Images/TextMenu/tx.png";
  const tabbartext =
    "https://fs.cdnxn.com/landingKing/Images/ImgPro/ImgPro1.png";
  const tx1 = "https://fs.cdnxn.com/landingKing/Images/TextMenu/tx1.png";
  const tx2 = "https://fs.cdnxn.com/landingKing/Images/TextMenu/tx2.png";
  const tx3 = "https://fs.cdnxn.com/landingKing/Images/TextMenu/tx3.png";
  const tx4 = "https://fs.cdnxn.com/landingKing/Images/TextMenu/tx4.png";
  const tx5 = "https://fs.cdnxn.com/landingKing/Images/TextMenu/tx5.png";
  const tx6 = "https://fs.cdnxn.com/landingKing/Images/TextMenu/tx6.png";
  const tx7 = "https://fs.cdnxn.com/landingKing/Images/TextMenu/tx7.png";
  const [closeMarquee, setCloseMarquee] = useState(true);
  const [linkLoginH, setLinkLoginH] = useState(
    "https://game.lsm99king.Bet/login"
  );
  const [linkRegisterH, setRegister] = useState(
    "https://game.lsm99king.Bet/register"
  );
  const [show, setShow] = useState(false);
  const [showNofi, setShowNofi] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseNofi = () => setShowNofi(false);
  const handleShowNofi = () => setShowNofi(true);

  return (
    <>
      <div>
        <div className="header">
          <div className="header-section">
            <div className="header--PC">
              <img src={HeadPc} alt="" className="Head--Pc"></img>
              <a href="/">
                <img src={IconHeadPc} alt="" className="Icon--Head--Pc"></img>
              </a>
              <div className="Login--Regis--Pc">
                <a href={linkRegisterH}>
                  <img src={RegisterPc} alt="" className="Register--Pc"></img>
                </a>
                <a href={linkLoginH}>
                  <img src={LoginPc} alt="" className="Login--Pc"></img>
                </a>
              </div>
              <div className="Icon--Noti--Menu--Pc">
                <img
                  src={IconNotifyPc}
                  alt=""
                  className="Icon--Notify--Pc"
                  onClick={handleShowNofi}
                ></img>
                <img
                  src={IconMenuPc}
                  alt=""
                  className="Icon--Menu--Pc"
                  onClick={handleShow}
                ></img>
              </div>
              <div className="Menu--Bar--Pc">
                <img src={TabMenuPc} alt="" className="Tab--Menu--Pc"></img>
                <a href={linkLoginH}>
                  <img
                    src={TabPlayIconPc}
                    alt=""
                    className="Tab--Play--Icon--Pc"
                  ></img>
                </a>
              </div>
              <div className="Icon--Games--Pc">
                <div>
                  <a href={linkLoginH}>
                    <img
                      src={IconWalletPc}
                      alt=""
                      className="Icon--Wallet--Pc"
                    ></img>
                  </a>
                </div>
                <div>
                  <a href={linkLoginH}>
                    <img
                      src={IconDepositPc}
                      alt=""
                      className="Icon--Deposit--Pc"
                    ></img>
                  </a>
                </div>
                <div>
                  <a href={linkLoginH}>
                    <img
                      src={IconWithdrawPc}
                      alt=""
                      className="Icon--Withdraw--Pc"
                    ></img>
                  </a>
                </div>
                <div>
                  <a href="/Promotion">
                    <img
                      src={IconPromotionPc}
                      alt=""
                      className="Icon--Promotion--Pc"
                    ></img>
                  </a>
                </div>
              </div>
              {closeMarquee === true ? (
                <>
                  <div className="Text--Show--Pc">
                    <img
                      src={TabBarTextPc}
                      alt=""
                      className="Tab--Bar--Text--Pc"
                    ></img>
                    <img
                      src={CloseTabTextPc}
                      alt=""
                      className="Close--Tab--Text--Pc"
                      onClick={() => setCloseMarquee(false)}
                    ></img>
                    <div className="text--tab--Pc">
                      <marquee scrollamount="6">
                        เมื่ออยากหาอะไรสนุกๆเล่นแก้เบื่อ ให้นึกถึงเรา LSM99KING
                        เว็บพนันออนไลน์ที่มีทั้งเกมส์ กีฬา คาสิโน หวย
                        พร้อมด้วยโปรโมชั่นที่รองรับการเล่นของสมาชิกทุกชนิด
                        บริการรวดเร็วทันใจด้วยระบบ Auto แอดมินบริการ 24 ชั่วโมง
                        ติดปัญหาส่วนใดเเจ้งน้องแอดมินได้ตลอดเลยนะคะ
                      </marquee>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="header--Mb">
              <img src={HeadMb} alt="" className="Head--Mb"></img>
              <a href="/">
                <img src={IconHeadMb} alt="" className="Icon--Head--Mb"></img>
              </a>
              <div className="Icon--Noti--Menu--Mb">
                <img
                  src={IconNotifyMb}
                  alt=""
                  className="Icon--Notify--Mb"
                  onClick={handleShowNofi}
                ></img>
                <img
                  src={IconMenuMb}
                  alt=""
                  className="Icon--Menu--Mb"
                  onClick={handleShow}
                ></img>
              </div>
              {closeMarquee === true ? (
                <>
                  <div className="Text--Show--Mb">
                    <img
                      src={TabBarTextMb}
                      alt=""
                      className="Tab--Bar--Text--Mb"
                    ></img>
                    <img
                      src={CloseTabTextMb}
                      alt=""
                      className="Close--Tab--Text--Mb"
                      onClick={() => setCloseMarquee(false)}
                    ></img>
                    <div className="text--tab--Mb">
                      <marquee scrollamount="6">
                        เมื่ออยากหาอะไรสนุกๆเล่นแก้เบื่อ ให้นึกถึงเรา LSM99KING
                        เว็บพนันออนไลน์ที่มีทั้งเกมส์ กีฬา คาสิโน หวย
                        พร้อมด้วยโปรโมชั่นที่รองรับการเล่นของสมาชิกทุกชนิด
                        บริการรวดเร็วทันใจด้วยระบบ Auto แอดมินบริการ 24 ชั่วโมง
                        ติดปัญหาส่วนใดเเจ้งน้องแอดมินได้ตลอดเลยนะคะ
                      </marquee>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="Footer--Mb">
                <img src={FooterMb} alt="" className="Footer--Mb"></img>
                <a href={linkLoginH}>
                  <img
                    src={TabPlayIconMb}
                    alt=""
                    className="Tab--Play--Icon--Mb"
                  ></img>
                </a>
                <div className="Icon--Games--Mb">
                  <a href={linkLoginH}>
                    <img
                      src={IconWalletMb}
                      alt=""
                      className="Icon--Wallet--Mb"
                    ></img>
                  </a>
                  <a href={linkLoginH}>
                    <img
                      src={IconDepositMb}
                      alt=""
                      className="Icon--Deposit--Mb"
                    ></img>
                  </a>
                  <a href={linkLoginH}>
                    <img
                      src={IconWithdrawMb}
                      alt=""
                      className="Icon--Withdraw--Mb"
                    ></img>
                  </a>
                  <a href="/Promotion">
                    <img
                      src={IconPromotionMb}
                      alt=""
                      className="Icon--Promotion--Mb"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showNofi}
        onHide={handleCloseNofi}
        className="showmenu--nofi--modal"
      >
        <div className="Close--Nofi--Pc">
          <img
            src={Iconclose}
            className="Icon--close--Nofi"
            alt=""
            onClick={handleCloseNofi}
          />
        </div>

        <div className="wrapper--menu--nofi">
          <div className="text--noti">
            <h2 className="textnoti">แจ้งเตือน</h2>
          </div>
          <div className="wrapper--section--nofi">
            <div>
              <div className="menu--nofi">
                <Container className="box--nofi">
                  <Row>
                    <img
                      src={tabbartext}
                      alt=""
                      className="bg--tab--blue--nofi"
                    />
                    <Tabs defaultActiveKey="tab-1">
                      <Tab eventKey="tab-1" title="ทั้งหมด">
                        <Row>
                          <Col xs={12} className="games mb-2">
                            คุณไม่มีข้อความ
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="tab-2" title="ฝากเงิน">
                        <Row>
                          <Col xs={12} className="games mb-2">
                            คุณไม่มีข้อความ
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="tab-3" title="ถอนเงิน">
                        <Row>
                          <Col xs={12} className="games mb-2">
                            คุณไม่มีข้อความ
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="tab-4" title="โบนัส">
                        <Row>
                          <Col xs={12} className="games mb-2">
                            คุณไม่มีข้อความ
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={show} onHide={handleClose} className="showmenu--modal">
        <div className="wrapper--menu">
          <div className="Close--menu--Pc">
            <img
              src={Iconclose}
              alt=""
              className="Icon--close--Menu"
              onClick={handleClose}
            />
          </div>
          <div className="wrapper--section-">
            <div>
              <div className="menu">
                <div className="text--bg--menu">
                  <div className="MBButton--tx1">
                    <a href={linkLoginH}>
                      <img src={tx1} alt="" className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx2">
                    <a href={linkLoginH}>
                      <img src={tx2} alt="" className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx3">
                    <a href={linkLoginH}>
                      <img src={tx3} alt="" className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx4">
                    <a href="/Promotion">
                      <img src={tx4} alt="" className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx5">
                    <a href={linkLoginH}>
                      <img src={tx5} alt="" className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx6">
                    <a href={linkLoginH}>
                      <img src={tx6} alt="" className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx7">
                    <a href={linkLoginH}>
                      <img src={tx7} alt="" className="tx--1"></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
